var Highcharts = require('highcharts');
import { TimeData } from '@/plugins/timeFun';
export const chartsObjket = {
    chart: {
        zoomType: 'x',//框选放大
        type: 'spline', //线样式
        panning: true, //是否移动
        panKey: 'ctrl', //按住ctrl可移动
        backgroundColor: 'rgba(0,0,0,0)',
        events: {
            selection: function (event) {
                console.log('Selection event triggered', event);
                // 在这里添加调试代码或检查数据加载状态  
            }
        }
    },

    title: {
        text: "",
        style: {
            color: '#5fc5ff' // 设置 Y 轴标题颜色为红色  
        }
    },
    subtitle: {
        text: "", // 设置副标题内容  
        useHTML: false,
        style: {
            color: '#5fc5ff', // 设置字体颜色  
            fontSize: '14px', // 设置字体大小  
            // fontWeight: 'bold' // 设置字体粗细  
        },
    },
    time: {
        useUTC: true
    },
    exporting: {
        enabled: false // 禁用导出功能，从而隐藏下载按钮  
    },
    boost: {
        useGPUTranslations: true
    },
    lang: {
        viewFullscreen: "全屏",
        exitFullscreen: "退出全屏",
        printChart: "打印图表",
        downloadJPEG: "下载JPEG图片",
        downloadPDF: "下载PDF文件",
        downloadPNG: "下载PNG文件",
        downloadSVG: "下载SVG文件",
    },
    xAxis: {
        //自定义x轴
        // categories: ["05-21", "05-22", "05-23", "05-24", "05-25", "05-26", "05-27"],
        title: {
            text: '时间',
            style: {
                color: '#5fc5ff' // 设置 x 轴标题颜色  
            },
        },
        type: 'datetime',
        lineColor: '#5fc5ff', // 设置 x 轴线条颜色 
        tickColor: '#5fc5ff', // 设置 x 轴刻度颜色 
        labels: {
            style: {
                color: '#5fc5ff' // 设置 x 轴标签颜色 
            }
        },
    },
    series: [],
    yAxis: {
        title: {
            text: '湿度%',
            style: {
                color: '#5fc5ff' // 设置 Y 轴标题颜色为红色  
            },
        },
        gridLineColor: '#5fc5ff', // 设置 X 轴网格线颜色为红色 
        lineColor: '#5fc5ff', // 设置 y 轴线条颜色为绿色  
        tickColor: '#5fc5ff', // 设置 y 轴刻度颜色为绿色  
        labels: {
            style: {
                color: '#5fc5ff' // 设置 y 轴标签颜色为红色  
            }
        },
        plotLines: []
    },
    legend: {
        itemStyle: {
            color: '#5fc5ff' // 设置图例字体颜色  
        }
    },
    credits: {
        //去掉水印
        enabled: false
    },
    // plotOptions: {
    //     series: {
    //         compare: 'percent',
    //         showInNavigator: true
    //     }
    // },
    tooltip: {
        valueSuffix: ' %%',
        backgroundColor: 'none', // 背景颜色
        borderWidth: 1, // 边框宽度  
        borderColor: '#AAA', // 边框颜色  
        shadow: true, // 是否显示阴影   
        style: { // 文字内容相关样式
            color: "#fff",
            fontSize: "12px",
            fontWeight: "blod",
            fontFamily: "Courir new"
        },
        crosshairs: [ //显示鼠标放上去的时候显示的提示标线
            {
                width: 1,
                color: '#5fc5ff'
            },
        ],
        formatter: function () {
            var point = this.point,
                series = this.series;
            var timestamp = point.x;
            var formattedDate = TimeData(timestamp); // 或者使用自定义格式  
            return `<p style='color:${point.color}'>${series.name}</p><br/>` +
                `<p style='color:${point.color}'>${formattedDate}</p><br/>` +
                `<p style='color:${point.color}'>${point.y}%</p><br/>`
        },
        shared: false
    },
    responsive: {
        rules: [{ // 在图表小于 500px 的情况下关闭图例
            condition: { // 响应条件
                maxWidth: 500
            },
            chartOptions: { // 响应内容
                legend: {
                    enabled: false
                }
            }
        }]
    }
}