<!-- 图表 -->
<template>
    <div>
        <div id="highchartsH" style="height: 100%;"></div>
    </div>
</template>

<script>
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
import draggable from 'highcharts/modules/draggable-points.js'
import { chartsObjket } from "../../plugins/setChartsH";
// 注册拖拽事件
draggable(Highcharts);
export default {
    props: {
        issetData:{
            type:Array,
            default:[]
        },
        seriesData: {//添加数据
            type: Object,
            default: {}
        },
        upSeriesData: {//更新数据
            type: String,
            default: ''
        },
        delSeriesDataH: {//删除数据
            type: String,
            default: ''
        },
        grainDelectH: {//彻底删除
            type: Boolean,
            default: false
        },

    },
    watch: {
        seriesData: {
            handler(data) {
                this.charts.addSeries(data);//添加
                this.seriesArray.push(this.issetData)
                this.evaluation()
                this.setplotLines()
            },
            deep: true,
        },
        delSeriesDataH(data) {
            var seriesIndex = this.charts.series.findIndex(function (series) {
                return series.name === data;
            });
            var seriesToRemove = this.charts.series[seriesIndex];
            if (seriesIndex !== -1) {
                this.seriesArray.splice(seriesIndex,1)
                seriesToRemove.remove();
                this.$emit('update:delSeriesDataH', '')
                this.evaluation()//计算和、平均值
                this.setplotLines()//上下限
            }

        },
        grainDelectH(data) {//彻底删除
            if (data) {
                this.charts.subtitle.textStr = ""//删除副标题
                this.charts.setTitle({text: ''});  //删除主标题
                this.seriesArray = []
                // 销毁图表实例  
                this.charts.destroy();
                this.$emit('update:grainDelectH', false)
                this.getChart()
            }
        },
        upSeriesData(data) {
            let seriesIndex
            this.charts.series.forEach(function (series, index) {
                if (series.name === data) {
                    seriesIndex = index;
                }
            });
            this.charts.series[seriesIndex].update({
                data: [
                    ['2024-11-01 01:00:00', 7],
                    ['2024-11-01 02:00:00', 9],
                    ['2024-11-01 03:00:00', 1],
                    ['2024-11-01 04:00:00', 1],
                ]
            }, true); // 第二个参数为 true 时，会立即触发重绘
            this.$emit('update:upSeriesData', '')
        }
    },
    data() {
        return {
            seriesArray:[],//存储原始数据
            charts: null,
            initPomers:{
                maximumvalue:0,//最大值
                minimumvalue:0,//最小值
                meanvalue:0,//平均值
            }
        }
    },
    created() {
        Highcharts.setOptions({
            lang: {
                resetZoom: '重置缩放比例',
                resetZoomTitle: '重置缩放比例'
            }
        });
    },
    mounted() {
        this.getChart()
    },
    methods: {
        updateSubtitle() { 
            this.charts.subtitle.textStr = `最高湿度:${this.initPomers.maximumvalue}% 最低湿度:${this.initPomers.minimumvalue}℃ 平均湿度:${this.initPomers.meanvalue}℃`
            this.charts.setTitle({text: '湿度曲线图'});  
        },
        setplotLines() {// 设置新的 plotLines  上下限
            let plotMap = []
            let maxMap = []//筛选出上限map
            let minMap = []//筛选出下限map
            this.seriesArray.forEach((item,index)=>{
                maxMap.push(item[item.length-1].T_rhu)
                minMap.push(item[item.length-1].T_rhl)
                maxMap = [...new Set(maxMap)]
	            minMap = [...new Set(minMap)]
            })
            maxMap.forEach(item=>{
                plotMap.push({
                    color: 'red',//线的颜色，定义为红色
                    dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                    value: item,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                    width: 1,//标示线的宽度，2px
                    id: "",                  //标示线的id，在删除该标示线的时候需要该id标示
                    label: {
                        text: `湿度上限（${item}%）`,//标签的内容
                        align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                        style: {
                            color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                        }
                    }
                })
            })

            minMap.forEach(item=>{
                plotMap.push({
                    color: 'red',//线的颜色，定义为红色
                    dashStyle: 'LongDashDotDot',//默认值，这里定义为实线
                    value: item,//定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
                    width: 1,//标示线的宽度，2px
                    id: "",                  //标示线的id，在删除该标示线的时候需要该id标示
                    label: {
                        text: `湿度下限（${item}%）`,//标签的内容
                        align: 'right',//标签的水平位置，水平居左,默认是水平居中center
                        style: {
                            color: '#5fc5ff' // 设置标签文本的字体颜色为蓝色  
                        }
                    }
                })
            })
            this.charts.yAxis[0].update({
                plotLines:[...plotMap]
            },true);
            if(this.charts.series.length!=0){//设置可视区域最大最小值
                let mapArr = []
                this.charts.series.forEach(item=>{
                    mapArr = [...mapArr,...item.yData]
                })
                let maxNum = Math.max(...mapArr)>Math.min(...maxMap)?Math.max(...mapArr):Math.min(...maxMap)
                let minNum = Math.min(...mapArr)>Math.min(...minMap)?Math.min(...minMap):Math.min(...mapArr)
                this.charts.yAxis[0].update({  
                    max : maxNum+2,
                    min : minNum-2
                });
            }
            // setTimeout(() => {
            //         this.charts.yAxis[0].removePlotLine('plot-line-2') //把id为plot-line-1的标示线删除
            //         console.log('删除18.5')
            //     }, 3000);
        },
        evaluation() {//求值
            let arr = [...this.charts.series]
            if(arr.length==0){
                this.charts.subtitle.textStr = ""
                this.charts.setTitle({text: ''}); 
                return
            }
            let arrMap = []
            arr.forEach(item => {
                arrMap = [...arrMap, ...item.yData]
            })
            let sum = arrMap.reduce((n, m) => n + m);//求和
            this.initPomers.maximumvalue = Math.max(...arrMap).toFixed(1) //最大值
            this.initPomers.minimumvalue = Math.min(...arrMap).toFixed(1) //最小值
            this.initPomers.meanvalue = parseFloat(sum / arrMap.length).toFixed(1)//平均值
            this.updateSubtitle()//更新标题
        },
        getChart() {
            this.charts = Highcharts.chart('highchartsH', {
                ...chartsObjket,
            });
        },
    }
}
</script>
<style lang="scss"></style>